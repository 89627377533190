<script>
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prettier/prettier */
</script>
<template>
  <v-toolbar class="overflow-hidden" flat dense rounded>
    <v-tabs show-arrows>
      <v-tabs-slider color="primary"></v-tabs-slider>
      <template>
        <v-tab to="/call-center/" exact>
          <v-icon class="mr-2">mdi-phone</v-icon>
          {{ $t("callView") }}
        </v-tab>
        <v-tab to="/call-center/missed-call" exact>
          <v-icon class="mr-2">mdi-phone-missed</v-icon>
          {{ $t("missedCalls") }}
        </v-tab>
        <v-tab v-if="isAdminT" to="/call-center/recorded-calls" exact>
          <v-icon class="mr-2">mdi-voicemail</v-icon>
          {{ $t("recordedCalls") }}
        </v-tab>
      </template>
      <template v-if="getMethodCalls == '3cx'">
        <v-tab to="/calls/" exact>
          <v-icon class="mr-2">mdi-phone</v-icon>
          {{ $t("callView") }}
        </v-tab>
        <v-tab to="/calls/missed-call" exact>
          <v-icon class="mr-2">mdi-phone-missed</v-icon>
          {{ $t("missedCalls") }}
        </v-tab>
        <v-tab v-if="isAdminT" to="/calls/recorded-calls" exact>
          <v-icon class="mr-2">mdi-voicemail</v-icon>
          {{ $t("recordedCalls") }}
        </v-tab>
      </template>

      <v-spacer />

      <div class="d-flex align-center pr-2">
        <!-- <btn-dialer /> -->
        {{/* Hide/Show call inprogress */}}
        <!-- <v-btn
          icon
          @click="showSidebarCallInProgress"
          :color="
            callCenterProvider.showSidebarCallInProgress
              ? 'primary'
              : 'text-secondary'
          "
        >
          <v-icon>mdi-phone</v-icon>
        </v-btn> -->

        <!-- <btn-make-call /> -->
        <template v-if="getMethodCalls == 'twilio'">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn
                color="primary"
                v-on="on"
                icon
                :disabled="dialogmakeCall"
                @click="openCall()"
              >
                <v-icon>mdi-phone-dial</v-icon>
              </v-btn>
            </template>
            <span>Make Call</span>
          </v-tooltip>
        </template>

        {{/* Hide/Show online workers */}}
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              :color="
                callCenterProvider.showSidebarOnlineUsers
                  ? 'green'
                  : 'text-secondary'
              "
              icon
              rounded
              v-on="on"
              v-bind="attrs"
              to="/call-center/online-agents"
            >
              <v-badge
                :content="badgeWorkersCount"
                :value="onlineWorkers.length > 0"
                offset-y="10"
                offset-x="10"
                color="red"
              >
                <v-icon color="green">mdi-account</v-icon>
              </v-badge>
            </v-btn>
          </template>
          {{ $t("labelOnlineWorkers") }}
        </v-tooltip>
      </div>
    </v-tabs>
  </v-toolbar>
</template>

<script>
/* eslint-disable @typescript-eslint/no-explicit-any */
import Vue from "vue";

import { mapGetters, mapState, mapMutations } from "vuex";
import BtnMakeCall from "../buttons/BtnMakeCall.vue";

export default Vue.extend({
  name: "secondary-toolbar",
  data() {
    return {
      isAdminT: false,
    };
  },

  inject: ["callCenterProvider"],
  components: { BtnMakeCall },
  computed: {
    ...mapState("crmCallCenterModule", ["onlineWorkers", "dialogmakeCall"]),
    ...mapGetters([
      "isSuper",
      "isAdmin",
      "isWorker",
      "isDoctor",
      "isSupervisor",
      "getMethodCalls",
    ]),

    badgeWorkersCount() {
      const workersCount = this.onlineWorkers.length;

      if (workersCount > 0 && workersCount <= 9) {
        return workersCount;
      } else if (workersCount == 0) {
        return "";
      }

      return "9+";
    },
  },
  methods: {
    ...mapMutations("crmCallCenterModule", ["mutDialogmakeCall"]),
    showSidebarCallInProgress() {
      this.callCenterProvider.showSidebarOnlineUsers = false;
      this.callCenterProvider.showSidebarCallInProgress =
        !this.callCenterProvider.showSidebarCallInProgress;
    },
    showSidebarOnlineUsers() {
      this.callCenterProvider.showSidebarCallInProgress = false;
      this.callCenterProvider.showSidebarOnlineUsers =
        !this.callCenterProvider.showSidebarOnlineUsers;
    },

    openCall() {
      localStorage.removeItem("number");
      this.mutDialogmakeCall(true);
    },
  },
  mounted() {
    if (this.isAdmin || this.isSuper || this.isSupervisor) {
      this.isAdminT = true;
    }
  },
});
</script>

<i18n>
{
  "en": {
    "callView": "Recent calls",
    "missedCalls": "Missed Calls",
    "onlineWorkers": "Online workers",
    "recordedCalls": "Recorded calls"
  },
  "es": {
    "callView": "Llamadas recientes",
    "missedCalls": "Llamadas perdidas",
    "onlineWorkers": "Agentes online",
    "recordedCalls": "Llamadas grabadas"
  }
}
</i18n>
