var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-toolbar',{staticClass:"overflow-hidden",attrs:{"flat":"","dense":"","rounded":""}},[_c('v-tabs',{attrs:{"show-arrows":""}},[_c('v-tabs-slider',{attrs:{"color":"primary"}}),[_c('v-tab',{attrs:{"to":"/call-center/","exact":""}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-phone")]),_vm._v(" "+_vm._s(_vm.$t("callView"))+" ")],1),_c('v-tab',{attrs:{"to":"/call-center/missed-call","exact":""}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-phone-missed")]),_vm._v(" "+_vm._s(_vm.$t("missedCalls"))+" ")],1),(_vm.isAdminT)?_c('v-tab',{attrs:{"to":"/call-center/recorded-calls","exact":""}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-voicemail")]),_vm._v(" "+_vm._s(_vm.$t("recordedCalls"))+" ")],1):_vm._e()],(_vm.getMethodCalls == '3cx')?[_c('v-tab',{attrs:{"to":"/calls/","exact":""}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-phone")]),_vm._v(" "+_vm._s(_vm.$t("callView"))+" ")],1),_c('v-tab',{attrs:{"to":"/calls/missed-call","exact":""}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-phone-missed")]),_vm._v(" "+_vm._s(_vm.$t("missedCalls"))+" ")],1),(_vm.isAdminT)?_c('v-tab',{attrs:{"to":"/calls/recorded-calls","exact":""}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-voicemail")]),_vm._v(" "+_vm._s(_vm.$t("recordedCalls"))+" ")],1):_vm._e()]:_vm._e(),_c('v-spacer'),_c('div',{staticClass:"d-flex align-center pr-2"},[_vm._v(" "+_vm._s(/* Hide/Show call inprogress */)+" "),(_vm.getMethodCalls == 'twilio')?[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"primary","icon":"","disabled":_vm.dialogmakeCall},on:{"click":function($event){return _vm.openCall()}}},on),[_c('v-icon',[_vm._v("mdi-phone-dial")])],1)]}}],null,false,2147209578)},[_c('span',[_vm._v("Make Call")])])]:_vm._e(),_vm._v(" "+_vm._s(/* Hide/Show online workers */)+" "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":_vm.callCenterProvider.showSidebarOnlineUsers
                ? 'green'
                : 'text-secondary',"icon":"","rounded":"","to":"/call-center/online-agents"}},'v-btn',attrs,false),on),[_c('v-badge',{attrs:{"content":_vm.badgeWorkersCount,"value":_vm.onlineWorkers.length > 0,"offset-y":"10","offset-x":"10","color":"red"}},[_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-account")])],1)],1)]}}])},[_vm._v(" "+_vm._s(_vm.$t("labelOnlineWorkers"))+" ")])],2)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }